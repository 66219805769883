$ = window.$  || window.jQuery;
import {PhoneNumberUtil, PhoneNumberType} from 'google-libphonenumber';
import '../css/application.css'
import '../css/timeline.css'

const phoneUtil = PhoneNumberUtil.getInstance();

$(document).ready(function(){
    window.Parsley.addValidator('phoneVerificationByCountryAndPrefix',
    {
        requirementType: 'string',
        validateString: function (value, requirement) {
            let requirement_country = requirement.split(';')[0];
            let requirement_prefix = requirement.split(';')[1];
            let country = $(requirement_country).val();
            value = $(requirement_prefix).val() + value;
            let phoneNumber = '';
            try{
                phoneNumber = phoneUtil.parse(value, country);
            }
            catch (error) {
                return false;
            }
            return phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isValidNumberForRegion(phoneNumber, country) && [PhoneNumberType.MOBILE, PhoneNumberType.FIXED_LINE_OR_MOBILE].includes(phoneUtil.getNumberType(phoneNumber));
        },
        messages: {
            'en': 'Invalid phone number',
        }
    });


    $('#phone-verification #id_country_code').change(function(){
        let countryCode = phoneUtil.getCountryCodeForRegion($(this).val());
         $('#phone-verification #id_phone_number_prefix').val('+'+countryCode);
    });

    $('#phone-verification #id_phone_number_prefix').change(function(){
        try {
            window.changeCountryCode(phoneUtil.getRegionCodeForCountryCode($(this).val().replace('+', '')));
        }
        catch (error){
            return false;
        }
    })
});

$(document).ready(function(){
    $('form#request-auth-code').submit(function(e){
        if($(this).find('button[type="submit"]').attr('disabled')) {
            return;
        }

        e.preventDefault();

        let form = $(this);
        form.find('button[type="submit"]').attr('disabled');
        form.find('#alert-response').fadeOut();

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            success: function(data){
                form.find('#alert-response').html(data);
                form.find('button[type="submit"]').removeAttr('disabled');
                form.find('#alert-response').fadeIn();
            }
        })
    });

    $('form#send-linking-email').submit(function(e){

        let button = $(this).find('button[type="submit"]');

        if(button.attr('disabled')) {
            return;
        }

        button.attr('disabled', "disabled");

        e.preventDefault();
        $('#alert-linking-code').fadeOut();

        let bundles = [];
        $('#send-linking-email input[type="checkbox"]:checked').each(function(ind, el){
            let valueToPush = {'registrar': $('#'+$(el).attr('id')+'_registrarAlias').val(), 'email': $('#'+$(el).attr('id')+'_email').val()};
            if(!bundles.some(bundle => bundle.registrar === valueToPush.registrar && bundle.email === valueToPush.email)){
                bundles.push(valueToPush);
            }
        });

        $.ajax({
            url: $(this).attr('action'),
            type: 'POST',
            data: {'csrfmiddlewaretoken': $(this).find('input[name="csrfmiddlewaretoken"]').val(), 'bundles': JSON.stringify(bundles)},
            success: function(data){
                $('#alert-linking-code').html(`<div class="alert alert-success">${data.message}</div>`);
                $('#alert-linking-code').fadeIn();
                 $('html, body').animate({
                    scrollTop: $("#alert-linking-code").offset().top
                }, 1000);
            },
            error: function(errorRes){
                if(errorRes.responseJSON.requestId){
                    $('#alert-linking-code').html(`<div class="alert alert-danger">${errorRes.responseJSON.error}<br/>${errorRes.responseJSON.requestIdText}</div>`);
                }
                else{
                    $('#alert-linking-code').html(`<div class="alert alert-danger">${errorRes.responseJSON.error}</div>`);
                }

                $('#alert-linking-code').fadeIn();
                $('html, body').animate({
                    scrollTop: $("#alert-linking-code").offset().top
                }, 1000);
            },
            complete: function(){
                button.removeAttr("disabled");
            }
        })
    });

    $('form[id^="action-request-auth-code"]').submit(function(e){
        e.preventDefault();

        let button = $(this).find('button[type="submit"]');

        if(button.attr('disabled')) {
            return;
        }

        button.attr('disabled', "disabled");

        let alertDisplayDiv = $('#alert-response-auth-code-action');

        $.ajax({
            url: $(this).attr('action'),
            type: 'POST',
            data: $(this).serialize(),
            success: function(data){
                alertDisplayDiv.html((alertDisplayDiv.html() || '') + data);
                alertDisplayDiv.fadeIn();
                 $('html, body').animate({
                    scrollTop: alertDisplayDiv.offset().top
                }, 1000);
            },
            error: function(errorRes){
                alertDisplayDiv.html((alertDisplayDiv.html() || '') + `<div class="alert alert-danger">${errorRes.responseJSON.error}</div>`);
                alertDisplayDiv.fadeIn();
                $('html, body').animate({
                    scrollTop: alertDisplayDiv.offset().top
                }, 1000);
            },
            complete: function(){
                button.removeAttr("disabled");
            }
        });
    });

    $('#select-all').click(function(){
        $('#send-linking-email input[type="checkbox"]:not(:checked)').each(function(ind, el){
            $(el).prop('checked', true);
            $(el).trigger('change')
        })
    });
});

$(document).ready(function () {
  $('#download-certificate-language.selectpicker').change(function () {
      $('a.download-certificate').attr('href', $(this).val());
  });
  $('#check-all').click(function(){
      $('#send-linking-email input[type="checkbox"]').prop('checked', true)
      $('#send-linking-email input[type="checkbox"]').trigger('change')
  })

  let refreshSendLinkingEmailButton = () => {
      // check if there's at least one checkbox checked
      if($('#send-linking-email input[type="checkbox"]:checked').length){
          $('#send-linking-email button[type="submit"]').removeClass('disabled')
          $('#send-linking-email button[type="submit"]').removeAttr('disabled')
      }
      else{
          $('#send-linking-email button[type="submit"]').addClass('disabled')
          $('#send-linking-email button[type="submit"]').attr('disabled', true)
      }

  }
  refreshSendLinkingEmailButton()
  $('#send-linking-email input[type="checkbox"]').change(function(){
    refreshSendLinkingEmailButton()
  })

  let refreshStartButton = () => {
      // check if .start-vr-form checkbox exists and checked
      if ($('.start-vr-form input[type="checkbox"]').length === 0 || $('.start-vr-form input[type="checkbox"]:not(:checked)').length) {
          $('.start-vr').addClass('disabled');
          $('.start-vr').attr('disabled', true);
          $('.start-vr.btn').attr('type', 'button');
          $('#confirm-data-panel').toggleClass('hidden', true);
      } else {
          $('.start-vr').removeClass('disabled');
          $('.start-vr').removeAttr('disabled');
          $('.start-vr.btn').attr('type', 'submit');
          $('#confirm-data-panel').toggleClass('hidden', false);
      }

  }
  refreshStartButton()
  $('.start-vr-form input[type="checkbox"]').change(function () {
      refreshStartButton()
  })

    // Function to check if all select pickers have value "true"
    function checkIfAllTrue() {
        return $('select.selpicker.data-confirm').toArray().every(picker => $(picker).val() === "True");
    }

    // Function to check if at least one select picker has value "false"
    function checkIfOneFalse() {
        return $('select.selpicker.data-confirm').toArray().some(picker => $(picker).val() === "False");
    }

    // Function to toggle button visibility based on select picker values
    function toggleButtonVisibility() {
        $('#start-validation-action').toggleClass('hidden', !checkIfAllTrue());
        $('#start-validation-confirmation').toggleClass('hidden', !checkIfAllTrue());
        $('#request-help-action').toggleClass('hidden', !checkIfOneFalse());
    }

    // Adding event listener to select pickers
    $('select.selpicker.data-confirm').change(toggleButtonVisibility);

    // Initially check the select picker values
    toggleButtonVisibility();

});

$(document).ready(function () {
    $('#domain-transfer').change( function() {
        var selectedOption = $(this).find('option:selected');
        var domainStatus = selectedOption.attr('domain_status');

        if (domainStatus === 'SUSPENDED') {
            $('#alert-nis2').fadeIn();
        } else {
            $('#alert-nis2').fadeOut();
        }
    });
});